import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import iconRightArrow from '../../static/images/icon/right-arrow.svg';

const Index = (props) => {
  const { intro } = props.data;

  return (
    <div className='intro-background'>
      <Layout bodyClass='page-index'>
        <SEO title='VNG Security Response Center' />
        <div className='intro'>
          <div className='container'>
            <div className='row justify-content-start'>
              <div className='col-12 col-md-7 col-lg-6 order-2 order-md-1 intro-container'>
                <div className='intro-container-content'>
                  <div dangerouslySetInnerHTML={{ __html: intro.html }} />
                  <div className='intro-button'>
                    <Link
                      className='intro-button-text'
                      to='/responsible-disclosure/'
                    >
                      Submit Vulnerability Report
                      <img className='intro-button-icon' src={iconRightArrow} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query IndexQuery {
    intro: markdownRemark(fileAbsolutePath: { regex: "/(index.md)/" }) {
      html
      frontmatter {
        title
      }
    }
  }
`;

export default Index;
